<script lang="ts">
    import { getSiteLink, Site } from '@nomodo/links'
    import {
        Alert,
        AppCard,
        Box,
        Button,
        Col,
        Container,
        Heading,
        Hero,
        Image,
        Section,
    } from '@nomodo/ui'
    import { createMutation } from '@tanstack/svelte-query'
    import { MetaTags } from 'svelte-meta-tags'

    import FormContact from '$lib/components/FormContact.svelte'
    import IconDiscord from '$lib/components/IconDiscord.svelte'
    import DefaultLayout from '$lib/layouts/Default.svelte'
    import { type ContactParams, submitContact } from '$lib/mutations'
    import { getTranslatedErrorMessage } from '$lib/utils'

    import type { PageData } from './$types'

    interface Props {
        data: PageData
    }

    const { data }: Props = $props()
    const mutation = createMutation<void, Error, ContactParams>({
        mutationFn: submitContact,
    })

    let videoPaused = $state(true)
    let video: HTMLVideoElement | undefined = $state()
</script>

<MetaTags
    canonical={`${import.meta.env.VITE_SITE_URL}`}
    description="Nomodo provides open-source applications as a service, fully managed with infrastructure, allowing you to focus entirely on building your project."
    facebook={{
        appId: import.meta.env.VITE_FB_ID,
    }}
    openGraph={{
        type: 'website',
        url: `${import.meta.env.VITE_SITE_URL}`,
        title: "Just focus on your code. We'll handle the production-ready apps and infrastructure.",
        description:
            'Nomodo provides open-source applications as a service, fully managed with infrastructure, allowing you to focus entirely on building your project.',
        images: [
            {
                url: `${
                    import.meta.env.VITE_CLOUDINARY_URL
                }/v1723373636/open-graph/for-developers_3_hdk5eh.png`,
                width: 1200,
                height: 527,
                alt: "Just focus on your code. We'll handle the production-ready apps and infrastructure.",
            },
        ],
        siteName: 'nomodo.io',
    }}
    title="Just focus on your code. We'll handle the production-ready apps and infrastructure."
    twitter={{
        cardType: 'summary_large_image',
        title: "Just focus on your code. We'll handle the production-ready apps and infrastructure.",
        description:
            'Nomodo provides open-source applications as a service, fully managed with infrastructure, allowing you to focus entirely on building your project.',
        image: `${
            import.meta.env.VITE_CLOUDINARY_URL
        }/v1723373499/open-graph/for-developers_zbpntm.png`,
        imageAlt:
            "Just focus on your code. We'll handle the production-ready apps and infrastructure.",
    }}
/>

<DefaultLayout>
    <Hero data-cy="hero" headingClass="text-balance">
        {#snippet preheading()}
            For software developers
        {/snippet}
        {#snippet heading()}
            Just focus on your code. <span
                >We'll handle the production&#8209;ready apps and infrastructure</span
            >
        {/snippet}

        {#snippet content()}
            <div class="flex w-full flex-col items-start lg:flex-row">
                <div class="col lg:w-7/12">
                    <div class="pr-8 lg:pr-0">
                        <p class="mb-8 text-xl leading-8 md:leading-10">
                            Nomodo provides open-source applications as a
                            service, fully managed with infrastructure, allowing
                            you to focus entirely on building your project.
                        </p>

                        <Button
                            href={getSiteLink(Site.admin)}
                            size="lg"
                            variant="success"
                        >
                            Start your project
                        </Button>
                    </div>
                </div>
            </div>
        {/snippet}
    </Hero>

    <Section id="how-it-works" variant="light">
        <Container>
            <Heading class="mb-6" level="2">
                Easily build your infrastructure with the technologies you need
                for your next big thing
            </Heading>

            <p class="mb-10">
                Explore the apps we currently support to power your projects.
            </p>

            <div class="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
                {#each data.apps.readyToUse as app}
                    <AppCard
                        active={app.meta.site.slug === 'medusajs'}
                        href={`/${app.meta.site.slug}`}
                    >
                        {#snippet image()}
                            <Image
                                class={app.meta.site.slug === 'medusajs'
                                    ? ''
                                    : 'opacity-50 grayscale'}
                                alt={app.title}
                                height={160}
                                lazy
                                src={`/w_160,f_auto,q_auto,fl_immutable_cache/${app.image}`}
                                width={160}
                            />
                        {/snippet}
                        {#snippet content()}
                            <svete:fragment>
                                <Heading
                                    class="mb-1"
                                    fontFamily="sans"
                                    level="3"
                                    size="6"
                                >
                                    {app.title}
                                </Heading>

                                <div class="text-sm leading-5">
                                    {@html app.description}
                                </div>
                            </svete:fragment>
                        {/snippet}
                    </AppCard>
                {/each}
            </div>
        </Container>
    </Section>

    <Section id="solution" variant="white">
        <Container>
            <Heading class="mb-6" level="2">
                Launch your app in a minute
            </Heading>
            <p class="mb-10">
                Discover how effortlessly you can launch your app with Nomodo
            </p>

            <div class="relative mb-10">
                <!-- svelte-ignore a11y_media_has_caption -->
                <video
                    bind:this={video}
                    class="object-cover object-left-top"
                    controls
                    poster="/poster-product.png"
                    bind:paused={videoPaused}
                >
                    <source src="/product.mp4" type="video/mp4" />
                </video>
            </div>

            <Button href={getSiteLink(Site.admin)} variant="success">
                Start your project
            </Button>
        </Container>
    </Section>

    <Section id="partnership" variant="light">
        <Container>
            <Heading class="mb-6" level="2">
                Nomodo saves you 20+ hours every month. <br /> You can just code.
                We do the rest
            </Heading>
            <Col lg="9">
                <ul class="mb-10 text-gray-500">
                    <li
                        class="relative mb-2 pl-10 before:absolute before:left-0 before:top-1 before:inline-block before:h-5 before:w-5 before:rounded before:bg-gray-200 before:indent-1 before:font-display before:text-xl before:leading-[.8] before:text-primary-500 before:content-['✔']"
                    >
                        Installing and configuring open source apps
                    </li>
                    <li
                        class="relative mb-2 pl-10 before:absolute before:left-0 before:top-1 before:inline-block before:h-5 before:w-5 before:rounded before:bg-gray-200 before:indent-1 before:font-display before:text-xl before:leading-[.8] before:text-primary-500 before:content-['✔']"
                    >
                        Server setup and maintenance
                    </li>
                    <li
                        class="relative mb-2 pl-10 before:absolute before:left-0 before:top-1 before:inline-block before:h-5 before:w-5 before:rounded before:bg-gray-200 before:indent-1 before:font-display before:text-xl before:leading-[.8] before:text-primary-500 before:content-['✔']"
                    >
                        Continuous app monitoring
                    </li>
                    <li
                        class="relative mb-2 pl-10 before:absolute before:left-0 before:top-1 before:inline-block before:h-5 before:w-5 before:rounded before:bg-gray-200 before:indent-1 before:font-display before:text-xl before:leading-[.8] before:text-primary-500 before:content-['✔']"
                    >
                        Real-time alerting
                    </li>
                    <li
                        class="relative mb-2 pl-10 before:absolute before:left-0 before:top-1 before:inline-block before:h-5 before:w-5 before:rounded before:bg-gray-200 before:indent-1 before:font-display before:text-xl before:leading-[.8] before:text-primary-500 before:content-['✔']"
                    >
                        Managing upgrades and downgrades
                    </li>
                    <li
                        class="relative mb-2 pl-10 before:absolute before:left-0 before:top-1 before:inline-block before:h-5 before:w-5 before:rounded before:bg-gray-200 before:indent-1 before:font-display before:text-xl before:leading-[.8] before:text-primary-500 before:content-['✔']"
                    >
                        Backup and disaster recovery
                    </li>
                    <li
                        class="relative mb-2 pl-10 before:absolute before:left-0 before:top-1 before:inline-block before:h-5 before:w-5 before:rounded before:bg-gray-200 before:indent-1 before:font-display before:text-xl before:leading-[.8] before:text-primary-500 before:content-['✔']"
                    >
                        Seamless horizontal and vertical scaling
                    </li>
                    <li
                        class="relative mb-2 pl-10 before:absolute before:left-0 before:top-1 before:inline-block before:h-5 before:w-5 before:rounded before:bg-gray-200 before:indent-1 before:font-display before:text-xl before:leading-[.8] before:text-primary-500 before:content-['✔']"
                    >
                        GitOps integration
                    </li>
                </ul>

                <Heading class="mb-6" fontFamily="sans" level="3">
                    Nomodo is the layer between you and your cloud provider,
                    giving you complete freedom.
                </Heading>

                <ul class="text-gray-500">
                    <li
                        class="relative mb-2 pl-10 before:absolute before:left-0 before:top-1 before:inline-block before:h-5 before:w-5 before:rounded before:bg-gray-200 before:indent-1 before:font-display before:text-xl before:leading-[.8] before:text-primary-500 before:content-['✔']"
                    >
                        Select from top global cloud providers
                    </li>
                    <li
                        class="relative mb-2 pl-10 before:absolute before:left-0 before:top-1 before:inline-block before:h-5 before:w-5 before:rounded before:bg-gray-200 before:indent-1 before:font-display before:text-xl before:leading-[.8] before:text-primary-500 before:content-['✔']"
                    >
                        Deploy in the region that best suits your needs
                    </li>
                    <li
                        class="relative mb-2 pl-10 before:absolute before:left-0 before:top-1 before:inline-block before:h-5 before:w-5 before:rounded before:bg-gray-200 before:indent-1 before:font-display before:text-xl before:leading-[.8] before:text-primary-500 before:content-['✔']"
                    >
                        Enjoy complete freedom to migrate between providers with
                        no vendor lock-in
                    </li>
                </ul>
            </Col>
        </Container>
    </Section>

    <Section variant="white">
        <Container>
            <Heading class="mb-6 text-center" level="2">Testimonials</Heading>

            <div
                style="width:100%;"
                class="famewall-embed"
                data-format="carousel"
                data-src="nomodo"
            ></div>
            <script
                defer
                src="https://embed.famewall.io/frame.js"
                type="text/javascript"
            ></script>
        </Container>
    </Section>

    <Section variant="light">
        <Container>
            <Col md="6">
                <Heading id="contact" class="mb-6" level="2">
                    Any questions?
                </Heading>
                <p class="mb-10">
                    If you have any questions or need help, please contact us.
                </p>
                {#if $mutation.error}
                    <Alert class="mb-4" variant="danger">
                        {@html getTranslatedErrorMessage(
                            $mutation.error.message
                        )}
                    </Alert>
                {/if}

                {#if $mutation.isSuccess}
                    <Alert class="mb-10" variant="success">
                        Thank you for your message!
                    </Alert>
                {:else}
                    <Box class="mb-10" variant="light">
                        <FormContact
                            loading={$mutation.isPending}
                            submit={(e) =>
                                $mutation.mutate({
                                    e,
                                    component: 'contact-form',
                                    slug: 'contact',
                                })}
                        />
                    </Box>
                {/if}

                <Heading class="mb-6" fontFamily="sans" level="3">
                    Join the community
                </Heading>
                <Button
                    class="inline-flex items-center gap-2"
                    href="https://discord.gg/eycYCn5tkZ"
                    variant="dark"
                >
                    <IconDiscord />
                    Discord server
                </Button>
            </Col>
        </Container>
    </Section>
</DefaultLayout>
